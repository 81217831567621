import type { AppRouter } from '@repo/backend/src/framework/trpc/app.router';
import { createTRPCProxyClient, httpBatchLink, TRPCClientError } from '@trpc/client';
import { inferRouterOutputs } from '@trpc/server';

export type AppRouterOutputs = inferRouterOutputs<AppRouter>;

export const TRPCClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: 'trpc',
    }),
  ],
});

export const isTRPCClientError = (error: any): error is TRPCClientError<AppRouter> => {
  return error instanceof TRPCClientError;
};
